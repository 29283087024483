// src/app/features/cost-unit/cost-unit.service.ts
import { Injectable, Signal, inject, signal } from '@angular/core';
import { Router } from '@angular/router';

interface CostUnitData {
    costUnit: string;
    timestamp: number;
}

@Injectable({
    providedIn: 'root'
})
export class CostUnitService {
    private readonly STORAGE_KEY = 'awo-care-cost-unit';
    private readonly EXPIRATION_TIME = 24 * 60 * 60 * 1000;
    private router = inject(Router);
    private costUnitSignal = signal<string | null>(this.getStoredCostUnit());
    readonly costUnit: Signal<string | null> = this.costUnitSignal.asReadonly();

    constructor() {
        this.cleanupExpiredCostUnit();
    }

    async processCostUnit(costUnit: string, targetRoute: string): Promise<boolean> {
        if (!this.isValidCostUnit(costUnit)) {
            return false;
        }

        this.storeCostUnit(costUnit);
        return this.router.navigate([targetRoute]);
    }

    private isValidCostUnit(costUnit: string): boolean {
        return /^[A-Z0-9]{3,10}$/.test(costUnit);
    }

    storeCostUnit(costUnit: string): void {
        const data: CostUnitData = {
            costUnit,
            timestamp: Date.now()
        };

        sessionStorage.setItem(this.STORAGE_KEY, JSON.stringify(data));
        this.costUnitSignal.set(costUnit);
    }

    getStoredCostUnit(): string | null {
        const storedData = sessionStorage.getItem(this.STORAGE_KEY);
        if (!storedData) return null;

        try {
            const data: CostUnitData = JSON.parse(storedData);
            if (Date.now() - data.timestamp > this.EXPIRATION_TIME) {
                sessionStorage.removeItem(this.STORAGE_KEY);
                return null;
            }
            return data.costUnit;
        } catch {
            sessionStorage.removeItem(this.STORAGE_KEY);
            return null;
        }
    }

    private cleanupExpiredCostUnit(): void {
        const storedData = sessionStorage.getItem(this.STORAGE_KEY);
        if (!storedData) return;

        try {
            const data: CostUnitData = JSON.parse(storedData);
            if (Date.now() - data.timestamp > this.EXPIRATION_TIME) {
                sessionStorage.removeItem(this.STORAGE_KEY);
                this.costUnitSignal.set(null);
            }
        } catch {
            sessionStorage.removeItem(this.STORAGE_KEY);
            this.costUnitSignal.set(null);
        }
    }

    clearCostUnit(): void {
        sessionStorage.removeItem(this.STORAGE_KEY);
        this.costUnitSignal.set(null);
    }
}